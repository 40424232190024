@use 'styles/colors' as *;

.root {
  position: relative;
  min-height: 70px;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $brand-primary-5;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition:
    background-color 0.5s ease-in-out,
    color 0.5s ease-in-out;
  color: $brand-primary-200;
  margin-bottom: 20px;

  &Disabled:hover,
  &Disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }

  &Selected:hover,
  &Selected {
    background-color: $brand-primary-90;
    color: $white;
  }

  .status {
    position: absolute;
    top: -8px;
    right: 5px;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
  }

  .name {
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  .tag {
    background-color: $status-pending-secondary;
  }
}
