@use 'styles/mixins' as *;

.root {
  max-width: 750px;
  width: 50%;
}

.order {
  @include flex-box-centered;

  width: 34px;
  height: 34px;
  color: $base-grey-50;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  grid-column: 1 / span 1;
  justify-self: start;
}

.typeSelector {
  grid-column: 2 / span 4;

  ul {
    width: 40rem !important;
  }
}

.textInput {
  width: 100%;
  grid-column: 6 / span 5;
  height: 4rem;
  padding: 9px;
}

.input {
  width: 100%;
  grid-column: 6 / span 5;
}

.removeButton {
  grid-column: 11 / span 1;
  justify-self: end;
}
