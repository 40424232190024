.root {
  position: relative;
  width: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      padding-bottom: 20px;
      font-size: 40px;
      font-weight: 900;
      color: #2e263e;
      z-index: 2;
      position: relative;
    }

    img {
      position: absolute;
      width: 200px;
      top: -20px;
      left: 72%;
      z-index: 1;
    }

    p {
      font-size: 15px;
      font-weight: 500;
      color: #514d5a;
      position: relative;
      z-index: 2;
      text-align: center;
    }

    a {
      padding-top: 1em;
    }

    .button {
      width: 80%;
      margin-top: 40px;
    }

    .confirmationButton {
      margin-top: 40px;
      background-color: #12c985;
      display: flex;
      align-items: center;
      justify-content: left;
      color: #fff;
      padding: 7px 30px;
      border-radius: 8px;

      svg {
        margin-right: 1em;
      }
    }

    .support {
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color: #514d5a;

      a {
        color: #7a48df;
      }
    }
  }
}
