@use 'styles/mixins' as *;

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $brand-primary-15;
    padding-bottom: 15px;
  }
}
