@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;

  .iconWrapper {
    @include flex-box-centered;
    width: 70px;
    height: 70px;

    .icon {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .title {
    margin: 1em 0;
  }

  .button {
    outline: none;
    border: $grey-border;
    border-radius: 100px;
    background-color: #eee;
    color: $base-grey-50;
    font-weight: 500;
    padding: 3px 15px;
  }

  &Completed {
    .button {
      color: $white;
      border: none;
      background-color: $status-completed-secondary;
    }
  }

  &Blocked {
    cursor: not-allowed;
    opacity: 0.6;

    .button {
      cursor: not-allowed;
    }
  }

  &Rejected {
    border-color: $error-color-background !important;

    .button {
      color: $white;
      border: none;
      background-color: $error-color-background;
    }
  }

  &Fixed {
    border-color: $status-pending-secondary !important;

    .button {
      color: $white;
      border: none;
      background-color: $status-pending-secondary;
    }
  }
}
