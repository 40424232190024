@use 'styles/variables' as *;

.root {
  display: flex;
  align-items: flex-start;
  padding: 30px;

  .itemIcon {
    background-color: $lime;
    color: $white;
    border-radius: 50%;
    padding: 2px;
  }
}
