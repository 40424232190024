@use 'styles/variables' as *;

.root {
  :global(.tab-content) {
    padding: 0;
  }

  .defaultIcon {
    margin-bottom: $margin-small;
    margin-right: $margin-small;

    color: $brand-primary-120;
    cursor: pointer;

    &Active {
      fill: $brand-primary-120;
    }
  }
}
