@use 'styles/colors' as *;

.root {
  margin-bottom: 25px;
  width: 45%;

  .order {
    color: $base-grey-90;
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    background-color: $base-grey-3;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    margin-right: 10px;
    min-width: 20px;
    z-index: 2;
  }

  .question {
    color: #000000;
    font-weight: 500;
  }

  .answers {
    padding-left: 30px;

    .namesContainer {
      max-width: 60px;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    div:first-child {
      margin-right: 15px;
    }
  }
}

.answer,
.name {
  margin-top: 10px;
}

.answer {
  .scaleLabel {
    color: #2e263e;

    &:nth-child(1) {
      padding-right: 10px;
    }

    &:nth-child(9) {
      padding-left: 10px;
    }
  }

  .scale {
    color: $base-grey-10;
    background-color: $base-grey-3;
    margin: 0 2px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    text-align: center;

    &.selected {
      background-color: $base-grey-50;
      color: $white;
      font-weight: 500;
    }
  }
}
