.root {
  position: relative;
  width: 830px;
  min-height: 780px;
  border-radius: 8px;
  background-color: white;
  border: solid 1px #e5dff2;

  .topRef {
    width: 0px;
  }

  .topButton {
    position: absolute;
    bottom: -15px;
    left: 47%;
  }
}
