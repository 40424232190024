@use 'styles/colors' as *;

.root {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.divider {
  margin: 1em 0;
  width: 100%;
  border: 1px solid $brand-primary-5;
}

.title {
  color: $brand-primary-200;
  font-size: 18px;
  font-weight: 700;
}

.subtitle {
  color: $brand-primary-160;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 2em;
}

.report {
  padding-left: 3em;
}

.card {
  margin: 1em 0;
}
