@use 'styles/variables' as *;

.root {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $base-grey-50;

  .canvas {
    border: 1px solid $base-grey-10;
    background-color: $base-grey-5;
    border-radius: 10px;
    width: 100%;
    height: 25rem;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $base-grey-10;
    background-color: $base-grey-5;
    border-radius: 10px;
    width: 100%;
    height: 25rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .button {
    top: 5.5rem;
    position: absolute;
    z-index: 2;
    border-radius: 3rem;
    font-family: inter, sans-serif;
    cursor: pointer;
    padding: 5px 15px;
    text-decoration: none;
    white-space: nowrap;
    font-size: 1.3rem;

    &Primary {
      right: 1.5rem;
      color: $green;
      background-color: $green-10;
      border: 1px solid $green;

      &:hover {
        border-color: $green-150;
        color: $green-150;
      }
    }

    &Secondary {
      left: 1.5rem;
      color: $red;
      background-color: $red-20;
      border: 1px solid $red;

      &:hover {
        color: $red-150;
        border-color: $red-150;
      }
    }
  }

  .load {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
