@use 'styles/colors' as *;

.root {
  overflow: hidden;
  width: 100%;
  top: 56px;
  position: fixed;
  z-index: 990;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: $white;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &.hidden {
    animation-name: pop-out;
    animation-timing-function: ease-out;
  }

  &:not(.hidden) {
    animation-name: pop-up;
    animation-timing-function: ease-in;
  }

  &.error {
    background-color: #ef113f;
  }
  &.success {
    background-color: #86dd77;
  }
  &.info {
    background-color: #c5a03a;
  }

  & > div {
    padding-left: 1em;
    display: inline-block;
    margin: 5px 0;
    font-weight: 500;
    font-size: 12px;

    & > a {
      color: $white;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & > svg {
    height: 20px;
    width: 20px;
  }
}

@keyframes pop-up {
  0% {
    height: 0;
    display: none;
    opacity: 0;
  }
  1% {
    height: 0;
    display: flex;
    opacity: 0;
  }
  100% {
    height: 30px;
    display: flex;
    opacity: 1;
  }
}
@keyframes pop-out {
  0% {
    height: 30px;
    display: flex;
    opacity: 1;
  }
  100% {
    height: 0;
    display: none;
    opacity: 0;
  }
}
