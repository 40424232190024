@use 'styles/mixins' as *;

.selectDropdown {
  padding: 10px 30px 20px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
}

.dropdownTabContent {
  display: flex;
  padding: 20px 30px 10px 30px;
}
