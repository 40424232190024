@use 'styles/colors' as *;

.root {
  position: relative;
  margin-bottom: 200px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.title {
  color: $brand-primary-200;
  font-size: 18px;
  font-weight: 700;
}
.subtitle {
  color: $brand-primary-160;
  font-size: 13px;
  font-style: italic;
}
.addModal {
  width: 450px;
}
.buttonWidth {
  width: 160px;
}
