@use 'styles/mixins' as *;

.checkTypesWrapper {
  max-height: 40vh;
  overflow-y: auto;
  margin: 20px 0;
  padding: 20px;
}

.checkType {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;

    &::before {
      position: absolute;
      top: 30px;
      bottom: -30px;
      width: 24px;
      content: '';
      border-right: 1px dotted $base-grey-25;
      z-index: 1;
    }
  }

  .logo {
    width: 48px;
    z-index: 2;
  }

  p {
    @include text-body;
    color: $base-grey-50;
    margin: 0;
    margin-top: 15px;
  }
}
