@use 'styles/colors' as *;

.root {
  position: relative;
  display: block;
  cursor: pointer;
  background-color: $white;
  border-radius: 10px;

  &Rejected {
    &::after {
      content: 'DECLINED';
      position: absolute;
      top: -12px;
      right: -6px;
      background-color: $status-error;
      color: $white;
      font-size: 10px;
      padding: 2px 5px;
      font-weight: 500;
      border-radius: 3px;
    }
  }

  .image {
    position: relative;
    padding: 10px;
    max-width: 300px;
    display: inline-block;
    transition: transform 0.4s ease;
    z-index: 1;

    &:hover {
      z-index: 2;
      transform: scale(1.5);
    }
  }

  .checkedIcon {
    position: absolute;
    right: -5px;
    top: -5px;
    color: $viking;
    height: 18px;
    width: 18px;
  }

  .pdfIcon {
    margin: 30px;
    height: 30px;
    width: 30px;
    color: $base-grey-80;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}
