.root {
  position: relative;
  width: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    color: #000000;
    padding-top: 1em;
  }
}
