@use 'styles/mixins' as *;

.root {
  margin: 15px 0;
}

.logoWrapper {
  @include flex-box-centered;
  height: 150px;
  width: 75%;

  .logo {
    max-height: 100%;
    max-width: 100%;
  }
}
