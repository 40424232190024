@use 'styles/variables' as *;

.document {
  color: $brand-primary-200;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    padding-bottom: 15px;
  }

  .icon {
    width: 16px;
  }
}
