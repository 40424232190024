@use 'styles/mixins' as *;

.root {
  position: relative;
}

.top {
  position: absolute;
  top: -60px;
}

.title {
  color: $brand-primary-200;
  font-size: 18px;
  font-weight: 700;
}

.subtitle {
  color: $brand-primary-200;
  font-size: 13px;
  font-style: italic;
}

.content {
  @include flex-box-start;
  margin-top: 10px;
}

.cardsContainer {
  @include flex-box-centered;
  flex-direction: column;

  p {
    text-transform: uppercase;
    width: 100%;
    margin: 15px 0 0 0;
    font-size: 11px;
    font-weight: 600;
    color: $brand-primary-200;
  }
}

.divider {
  width: 100%;
  height: 0;
  border-bottom: 1px solid $brand-primary-5;
}

.formContainer {
  padding-left: 4em;
}

.card {
  margin: 1em 0;
}
