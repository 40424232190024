@use 'styles/mixins' as *;

.root {
  display: block;
  position: absolute;
  top: 57px;
  right: 24px;
  min-width: 250px;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;

  &Hidden {
    display: none;
  }

  .info {
    .userName {
      color: $base-grey;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    .userEmail {
      color: $base-grey-50;
      font-size: 11px;
      margin: 0;
    }

    .accountBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 25px;
      margin-top: 1em;
      width: 100%;
      background-color: #6532cd;
      color: $white;
      border-radius: 10px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  hr {
    margin: 0 1em;
  }

  .linksWrapper {
    display: flex;
    flex-direction: column;
    padding: 1em 0;

    .link {
      padding: 0 15px;
      list-style-type: none;
      line-height: 30px;
      text-transform: uppercase;
      font-size: 12px;
      transition: background-color 0.4s;
      display: flex;
      align-items: center;
      color: $base-grey;
      text-decoration: none;
      cursor: pointer;

      svg {
        @include text-body;
        margin-right: 7px;
        height: 16px;
        width: 16px;
        transition: color 0.4s;
      }

      &:hover {
        background-color: #f3f2f6;

        svg {
          color: $brand-primary;
        }
      }
    }
  }
}
