@use 'styles/mixins' as *;

.body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  width: 450px;
  overflow: hidden;
}

.forms {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 950px;
  transition: margin 0.4s ease-in-out;

  &--step2 {
    margin-left: -500px;
  }
}
