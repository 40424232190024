@use 'styles/mixins' as *;

.root {
  position: relative;
  margin-top: 2em;
  background-color: $white;
  border: $grey-border;
  border-radius: 24px;
  width: 100%;

  &Dummy {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.4;
      background-color: $base-grey-50;
      border-radius: 24px;
    }
  }
}

.subCheckExpandIcon {
  position: absolute;
  color: $white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  right: 11px;
  background-color: $brand-primary;
}

.titleName {
  font-size: 17px;
  font-weight: 500;
}

.titleStatus {
  text-transform: uppercase;
  font-size: 11px;
}

.dateTitle {
  text-transform: uppercase;
  font-size: 11px;
  color: $brand-primary-40;
  font-weight: 500;
}

.dateValue {
  color: $brand-primary-160;
  font-weight: 500;
}

.logo {
  width: 32px;
  height: 32px;
}

.iconButtonRotated {
  transition: transform 0.4s ease-in-out;
  transform: rotate(180deg);
}

.logo {
  width: 32px;
  height: 32px;
}

.checkRow {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  .divider {
    display: none;

    @include viewportLarge {
      display: block;
      grid-column: 1 / 31;
      grid-row: 2 / 3;
    }
  }

  .divider2 {
    display: none;

    @include viewportSmall {
      display: block;
      grid-column: 1 / 31;
      grid-row: 4 / 5;
    }
  }

  .logoColumn {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .titleColumn {
    grid-column: 2 / 11;
    grid-row: 1 / 2;
    padding-left: 5px;

    @include viewportXLarge {
      grid-column: 2 / 11;
      padding-left: 10px;
    }

    @include viewportLarge {
      grid-column: 2 / 15;
    }

    @include viewportSmall {
      grid-column: 3 / 21;
      padding-left: 0px;
    }
  }

  .createdOnColumn {
    grid-column: 11 / 14;

    @include viewportLarge {
      grid-column: 1 / 7;
      grid-row: 3 / 4;
    }

    @include viewportSmall {
      grid-column: 1 / 11;
    }
  }

  .completedOnColumn {
    grid-column: 14 / 17;

    @include viewportLarge {
      grid-column: 7 / 13;
      grid-row: 3 / 4;
    }

    @include viewportSmall {
      grid-column: 11 / 21;
    }
  }

  .expiredOnColumn {
    grid-column: 17 / 20;

    @include viewportLarge {
      grid-column: 13 / 19;
      grid-row: 3 / 4;
    }

    @include viewportSmall {
      grid-column: 22 / 31;
    }
  }

  .statusColumn {
    grid-column: 20 / 23;

    @include viewportLarge {
      grid-column: 19 / 31;
      grid-row: 3 / 4;
    }

    @include viewportSmall {
      grid-column: 16 / 31;
      grid-row: 5 / 6;
      justify-self: end;
    }
  }

  .reviewColumn {
    grid-column: 20 / 25;
    grid-row: 1 / 2;

    @include viewportLarge {
      grid-column: 14 / 20;
    }

    @include viewportSmall {
      grid-column: 1 / 15;
      grid-row: 5 / 6;
    }
  }

  .actionsColumn {
    grid-column: 26 / 31;
    grid-row: 1 / 2;
    justify-self: end;

    @include viewportLarge {
      grid-column: 15 / 31;
    }

    @include viewportSmall {
      grid-column: 21 / 31;
    }
  }
}

.subCheckRow {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #fdfbfb;

  &:last-of-type {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .divider {
    display: none;

    @include viewportSmall {
      display: block;
      grid-row: 2 / 3;
      grid-column: 1 / 31;
    }
  }

  .titleColumn {
    grid-column: 2 / 11;
    grid-row: 1 / 2;

    @include viewportXLarge {
      grid-column: 3 / 11;
    }

    @include viewportLarge {
      grid-column: 1 / 10;
    }

    @include viewportSmall {
      grid-column: 1 / 25;
    }
  }

  .createdOnColumn {
    grid-column: 11 / 14;

    @include viewportLarge {
      grid-column: 10 / 15;
    }

    @include viewportSmall {
      grid-row: 3 / 4;
      grid-column: 1 / 11;
    }
  }

  .completedOnColumn {
    grid-column: 14 / 17;

    @include viewportLarge {
      grid-column: 15 / 20;
    }

    @include viewportSmall {
      grid-row: 3 / 4;
      grid-column: 11 / 21;
    }
  }

  .expiredOnColumn {
    grid-column: 17 / 20;

    @include viewportLarge {
      grid-column: 20 / 25;
    }

    @include viewportSmall {
      grid-row: 3 / 4;
      grid-column: 22 / 31;
    }
  }

  .actionsColumn {
    grid-column: 23 / 31;
    grid-row: 1 / 2;
    justify-self: end;

    @include viewportLarge {
      grid-column: 25 / 31;
    }

    @include viewportSmall {
      grid-column: 25 / 31;
    }
  }
}
