@use 'styles/mixins' as *;

.root {
  display: flex;

  .step {
    position: relative;
    @include flex-box-centered;
    margin: 0 10px;
    font-weight: 600;
    width: 20px;
    height: 20px;
    color: $brand-primary-200;
    border-radius: 100%;
    border: 1px solid $base-grey-10;
    background-color: $base-grey-5;

    &:not(:last-of-type) {
      &::before {
        top: 0;
        left: 100%;
        height: 10px;
        position: absolute;
        content: '';
        border-bottom: $grey-border;
        width: 30px;
        z-index: 0;
      }
    }
  }
}
