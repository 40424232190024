.mobileView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em;

  .form {
    margin: 0;
    width: 100%;

    .content {
      width: 100%;
    }
  }
}

.embed {
  width: 90%;
  height: 70vh;
  max-width: 1000px;
  border: none;
}

.form {
  margin: 4rem 0 4em 50%;
  width: 90%;
  max-width: 1000px;

  .content {
    width: 50%;
  }
}

.title {
  margin: 4rem;
  width: 90%;
  max-width: 1000px;
}
