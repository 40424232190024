@use 'styles/colors' as *;

.root {
  height: 100vh;
  overflow: auto;
  padding: 20px;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h3 {
    margin-bottom: 25px;
  }
}

.emailModal {
  background-color: $white;
  border-radius: 4px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.resultMsj {
  font-weight: 600;

  &Succeed {
    color: $status-completed;
  }

  &Error {
    color: $status-error;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
  width: 400px;

  .button {
    margin: 0.5em 0;
    width: 150px;
  }
}

.checkbox {
  position: absolute;
  left: -30px;
  top: 0;
}

.actions {
  display: flex;
}

.checkDetails {
  margin-top: 2em;
  padding: 5px;
  border: 1px solid $brand-primary-5;
  border-radius: 8px;
  width: 100vw;
  max-width: 500px;

  &_Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_Title {
    color: $brand-primary-160;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
  }

  &_CheckIcon {
    height: 14px;
    margin-left: 5px;
  }

  &_Logo {
    margin-right: 15px;
    width: 40px;
  }

  &_TitleName {
    font-size: 12px;
  }

  &_TitleStatus {
    font-size: 11px;
  }

  &_ActionButton {
    margin-left: 7px;

    &:not(:hover) {
      background-color: $brand-primary-70;
    }
  }

  &_RefereesWrapper {
    margin: 15px 15px 0 calc(40px + 15px);
  }

  &_Referee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &Incomplete {
      .name {
        color: $base-grey-40;
      }
    }
  }
}
