@use 'styles/colors' as *;

.button {
  cursor: pointer;
  transition: background-color 0.4s;
  position: relative;
  z-index: 2;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  box-sizing: border-box;
  border-radius: 1000px;
  padding: 10px 16px;
  width: 190px;
  height: 56px;
  text-align: center;
}
