@import '~bootstrap/scss/tooltip';

.tooltip {
  z-index: 99999;
}

.tooltip-inner {
  color: #fff;
  border-radius: 4px;
  background-color: #000;
  opacity: 0.9 !important;
  font-size: 12px;
  white-space: pre-wrap;
}
