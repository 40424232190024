@use 'styles/colors' as *;

.option {
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  .icon {
    color: $base-grey-50;
    margin-right: 5px;
  }

  .input {
    width: 100%;

    input {
      background-color: $white !important;
    }
  }
}
