.root {
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: transparent;
  transition: background-color 1s;

  &Active {
    z-index: 10000;
    background-color: #332854e0;

    .spinner {
      width: 40px;
      height: 40px;
      position: relative;
      margin: 100px auto;
    }

    .doubleBounce1,
    .doubleBounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgb(233, 233, 233);
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .doubleBounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
