@use 'styles/mixins' as *;

.root {
  position: relative;
  padding: 0 15px;
  height: 48px;
  text-transform: none;
  font-size: 15px;
  width: 100%;
  background-color: var(--button-color);
  color: var(--button-text-color);

  @include pseudo-states() {
    background-color: var(--button-color);
    color: var(--button-text-color);
  }

  &Completed {
    border-width: 2px;

    .completedIcon {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      padding: 2px;
      color: $white;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  &.small {
    height: 40px;
  }

  &--transparent {
    background-color: $white;
    color: var(--button-color);

    @include pseudo-states() {
      background-color: $white;
      color: var(--button-color);
    }
  }

  &--outline {
    background-color: transparent;
    color: var(--button-color);
    border-color: var(--button-color);

    @include pseudo-states() {
      background-color: transparent;
      color: var(--button-color);
      border-color: var(--button-color);
    }
  }

  &--secondary {
    background-color: $base-grey-5;
    color: $brand-primary-200;
    border-color: $base-grey-10;

    @include pseudo-states() {
      background-color: $base-grey-5;
      color: $brand-primary-200;
      border-color: $base-grey-10;
    }
  }
}
