@use 'styles/mixins' as *;

input[type='checkbox'] {
  outline: none !important;
  -webkit-appearance: none;
  appearance: none;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  margin: 0;
}
.checkbox {
  width: 2rem;
  height: 2rem;
  border: 1px solid $base-grey-10;
  border-radius: 3px;
  transition: 0.3s all;
  position: relative;
  background-color: $base-grey-5;

  &:hover {
    background-color: $white;
  }
}

.checkboxActive {
  width: 2rem;
  height: 2rem;
  border: 1px solid $brand-color;
  background-color: $brand-color;
  border-radius: 3px;
  transition: 0.3s all;
  position: relative;

  &:hover {
    background-color: $brand-color-dark;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 40%;
    height: 45%;
    width: 2px;
    background-color: $white;
    content: '';
    transform: translateX(7px) rotate(-45deg);
    transform-origin: left bottom;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 70%;
    background-color: $white;
    content: '';
    transform: translateY(-4px) translateX(8px) rotate(-45deg);
    transform-origin: left bottom;
  }
}
