.cardsWrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
  max-width: 650px;
  justify-content: center;
}

@media screen and (max-width: 426px) {
  .card_root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
