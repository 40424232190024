@use 'styles/colors' as *;

.root {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 500px;
  margin-top: 15px;
}

.filesWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.fileWrapper {
  margin-right: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid $brand-primary-40;
  border-radius: 5px;
  padding: 5px;

  img {
    max-width: 100px;
    max-height: 100px;
  }

  &Selected {
    &::after {
      content: '✔';
      position: absolute;
      top: -6px;
      right: -6px;
      background-color: $brand-primary;
      color: $white;
      font-size: 10px;
      padding: 2px 5px;
      font-weight: 500;
      border-radius: 3px;
    }
  }
}
