@use 'styles/variables' as *;

.container {
  margin-bottom: 30px;

  .question {
    font-size: 14px;
    font-weight: 400;
    color: $base-grey;

    a {
      color: $pacific-blue;
      font-style: italic;
      text-decoration: underline;
    }

    &WithError {
      color: $error-color;
    }
  }

  .description {
    font-size: 14px;
    color: $base-grey-60;

    a {
      color: $base-grey-60;
      font-style: italic;
    }
  }

  .statement {
    margin-bottom: 2em;
    font-weight: 500;
  }

  .textarea textarea,
  .filePicker,
  .signature {
    margin-top: 0;
    margin-bottom: 2em;
  }

  input {
    font-weight: 400;
    color: $base-grey;
  }
}

.phone {
  height: 40px;

  input {
    font-size: 1.4rem !important;
    font-weight: 400;
    color: $base-grey;
  }
}
