@use 'styles/fonts' as *;
@use 'styles/mixins' as *;

.root {
  &:not(&Disabled) {
    cursor: pointer;
  }
}

.editIcon {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  color: $base-grey;
}

.input {
  @include text-h2;
  font-family: 'urw-gothic';
  background-color: transparent;
  color: $base-grey;
  outline: none;
  border: none;
  padding: 0;
  width: 80%;
}
