.refereeDetails {
  .refereeCardinal {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 13px;
    text-decoration: underline;
    margin-bottom: 15px;

    &::before {
      content: '·';
      text-decoration: none;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .details {
    margin-left: 10px;
  }
}
