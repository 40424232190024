@use 'styles/colors' as *;

.root {
  margin: 30px;

  .titles {
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .name {
      .label {
        padding: 0;
        text-transform: uppercase;
        color: $base-grey-35;
        font-weight: 500;
        font-size: 12px;
      }

      .value {
        color: #2e263e;
        font-size: 24px;
        font-weight: bold;
      }

      .details {
        color: #8b8596;
      }
    }
  }

  .verificationCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
