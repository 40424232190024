@use 'styles/colors' as *;

.root {
  display: flex;
  align-items: center;

  .column {
    display: flex;
    flex-direction: column;
    margin-right: 2em;

    .title {
      text-transform: uppercase;
      color: $base-grey;
      font-size: 10px;
      font-weight: 500;
    }

    .data {
      color: $base-grey;
      font-weight: 500;
    }
  }

  .action,
  .status {
    margin-right: 1.5em;
    margin-left: 0;
  }
}
