.root {
  color: #2e263e;

  .section {
    padding: 1em;
    border: 1px solid #e5dff2;
    border-radius: 8px;

    .title {
      font-size: 15px;
      font-weight: 600;
    }

    .answersQuantity {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .content {
    margin-top: 1em;
  }

  .criticalResponses,
  .skills {
    width: 49%;
    margin-bottom: 1em;
  }

  .criticalResponses {
    .content {
      p {
        display: flex;
        align-items: flex-start;
        font-size: 13px;
      }

      :global(.feather) {
        height: 16px;
        margin-top: 1px;
      }

      :global(.feather-check-circle) {
        color: #2ec45f;
      }

      :global(.feather-alert-circle) {
        color: #ef6611;
      }
    }
  }

  .additionalComment .content {
    font-size: 16px;
  }
}
