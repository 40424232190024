@use 'styles/colors' as *;

.root {
  background-color: $base-grey-5;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.row {
  position: relative;
  max-width: 90%;
  margin-bottom: 15px;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $brand-primary-80;
    top: 17px;
    left: 12px;
  }

  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 25px);
    border-left: 1px dotted $brand-primary-80;
    top: 20px;
    left: 20px;
    z-index: 99;
  }

  &Pending {
    height: 50px;

    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $brand-primary-80;
      top: 16px;
      left: 12px;
      box-shadow: 0 0 0 0 rgba(122, 72, 223, 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(122, 72, 223, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(122, 72, 223, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(122, 72, 223, 0);
  }
}
