@use 'styles/colors' as *;

.root {
  position: fixed;
  background-color: transparent;
  overflow: hidden;
  z-index: 9999;
  bottom: -200%;
  right: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  transition:
    visibility 0.3s ease-in-out,
    bottom 0.3s ease-in-out,
    background-color 0.3s ease-in-out 0.1s;

  &Visible {
    background-color: rgba(46, 38, 62, 0.4);
    visibility: visible;
    bottom: 0;
  }
}

.contentWrapper {
  overflow-y: auto;
  position: relative;
  padding: 40px;
  padding-top: 0;
  background-color: $white;
  box-shadow: 2px 4px 14px 0px $base-grey-60;
  border-radius: 8px;
  max-height: 95%;
  max-width: 95%;

  &Small {
    width: 700px;
    max-width: 90%;
  }
}

.title {
  top: 0;
  right: 0;
  left: 0;
  text-transform: capitalize;
  font-size: 22px;
  margin-top: 29px;
  text-align: center;
  padding: 0 40px;
}

.closeBtn {
  position: absolute;
  cursor: pointer;
  top: 25px;
  right: 25px;
  width: 30px;
  color: $base-grey-25;
  z-index: 2;
  height: auto;
}
