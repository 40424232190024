@use 'styles/colors' as *;

.root {
  .button {
    z-index: 2147483000;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $brand-primary-120;
    color-scheme: none;
    box-shadow:
      rgb(0 0 0 / 6%) 0px 1px 6px 0px,
      rgb(0 0 0 / 16%) 0px 2px 32px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .logo {
      max-width: 60%;
      margin-bottom: 4px;
    }
  }

  .dialog {
    z-index: 2147483000;
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 280px;
    min-height: 250px;
    max-height: 704px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
    opacity: 1;
    border-radius: 8px;
    overflow: hidden;
    background-color: $white;
    transition:
      box-shadow ease 0s,
      height ease 0s,
      max-height ease 0s;

    &Closed {
      min-height: 0;
      max-height: 0;
      height: 0;
      box-shadow: none;
    }

    .header {
      background-color: $brand-primary-120;
      transition: height 160ms ease-out 0s;
      padding: 15px 25px;

      h3 {
        color: $white;
      }

      p {
        color: $base-grey-10;
      }
    }

    .message {
      margin: 10px;
      border-radius: 3px;
      box-shadow:
        rgb(0 0 0 / 10%) 0px 4px 15px 0px,
        rgb(0 0 0 / 10%) 0px 1px 2px 0px,
        rgb(45 38 77 / 50%) 0px 2px 0px 0px inset;
      padding: 20px;
    }
  }
}
