@use 'styles/mixins' as *;

.list {
  width: 35rem;
  transition: height 0.35s ease;
}

.content {
  justify-content: stretch;
  height: 100%;
  transition: height 0.35s ease;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  width: 100%;
}

.items {
  background-color: $base-grey-3;
  border: 1px solid $base-grey-10;
  border-radius: 6px;
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 1rem;
  height: 100%;
  overflow: scroll;
  transition: height 0.35s ease;

  .item {
    list-style: none;
    line-height: 3.6rem;
    padding: 0 1.5rem;
    transition: background-color 0.4s;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $base-grey-10;
    }

    &Icon {
    }
  }

  .itemHeader {
    @include text-h6;
    padding: 0 1.5rem;
  }
}
