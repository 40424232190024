.root {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .pulsing {
    fill: rgba($color: #000000, $alpha: 0.85);
    opacity: 0.9;
    animation: pulse 2s infinite ease-in-out;
  }
}

.spinner {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 14px);
  color: #7dff5ca5;
}

@keyframes pulse {
  0% {
    stroke-width: 4;
    stroke: #7dff5c;
  }
  50% {
    stroke-width: 6;
    stroke: #7dff5c;
  }
  100% {
    stroke-width: 4;
    stroke: #7dff5c;
  }
}
