@use 'styles/colors' as *;

.root {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;

  .candidate {
    & > div:first-child {
      width: 30%;
    }
    & > div:not(:first-child) {
      width: 17%;
    }
  }
}

.verificationCard {
  border: 1px solid $brand-primary-5;
  border-radius: 8px;
  width: 32%;
  padding: 1em;
  overflow: hidden;

  & > .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    color: $base-grey-35;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 12px;

    svg {
      height: 17px;
      margin-right: 5px;
    }
  }

  .referee {
    margin-top: 7px;
    padding-bottom: 7px;

    &:not(:last-child) {
      border-bottom: 1px solid $brand-primary-5;
    }

    .title {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: $base-grey-35;
    }

    .value {
      a {
        color: $brand-color;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .staticValue {
        color: #60527c;
      }

      .error {
        display: flex;
        font-size: 11px;
        color: #ef113f;
      }

      svg {
        height: 13px;

        &:global(.feather-check-circle) {
          color: #2ec45f;
        }

        &:global(.feather-alert-triangle) {
          color: #ef6611;
        }

        &:global(.feather-alert-circle) {
          display: flex;
          font-size: 11px;
          color: #ef113f;
        }
      }
    }
  }
}

.summary {
  margin-top: 20px;

  .criticalResponses,
  .skills {
    width: 49%;
    margin-bottom: 1em;
  }

  .section {
    padding: 1em;
    border: 1px solid $brand-primary-5;
    border-radius: 8px;

    .title {
      font-size: 15px;
      font-weight: 600;
    }

    .answersQuantity {
      font-size: 13px;
      font-weight: 500;
    }

    .content {
      margin-top: 1em;

      .question {
        font-size: 13px;
        margin-bottom: 4px;
      }

      .answers {
        svg {
          width: 15px;
          margin-right: 2px;

          &:global(.feather-alert-circle) {
            color: #ef6611;
          }

          &:global(.feather-check-circle) {
            color: #2ec45f;
          }
        }
      }
    }
  }
}
