@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    min-width: 40px;
    max-width: 70px;
    max-height: 70px;
  }

  .idsWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    column-gap: 15px;
    row-gap: 15px;
    justify-content: center;

    .idButton {
      display: flex;
      width: 250px;
      padding: 0 10px;

      .idButtonText {
        overflow: hidden;
        flex: 0 1 auto;
        white-space: pre;
        text-overflow: ellipsis;
      }
    }
  }
}
