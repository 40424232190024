@use 'styles/colors' as *;

.root {
  max-height: 0;
  background-color: $white;
  position: absolute;
  box-shadow: 2px 4px 14px 0px $base-grey-60;
  border-radius: 8px;
  right: 0;
  transition: max-height 0.2s ease-in-out;
  overflow-y: auto;
  padding-left: 0;
  z-index: 9;

  &Visible {
    max-height: 300px;
  }

  &.bottom {
    top: calc(100% + 17px);
  }

  &.top {
    bottom: calc(100% + 17px);
  }

  li {
    padding: 10px 20px;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: $brand-primary-5;
    }
  }

  .removeOption {
    color: $status-error-secondary;
  }
}
