@use 'styles/mixins' as *;

.integration {
  margin-top: 2em;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px $base-grey-10;
  transition: box-shadow 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 5px $base-grey-10;
  }

  .logo {
    border-radius: 6px;
    margin: 7px;
    width: 42px;
    height: 42px;
  }

  .name {
    margin: 0;
    font-weight: 100;
    font-size: 20px;
    padding-left: 0.5em;
  }
}
