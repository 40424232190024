.root {
  .frameWrapper {
    height: 70vh;

    iframe {
      border: none;
      border-radius: 10px;
      height: 100%;
      width: 500px;
      max-width: 90vw;
    }
  }
}
