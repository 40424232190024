.u-txt-status-pending,
.u-txt-status-candidate {
  color: $status-pending-secondary;
}
.u-status-pending:not(.disabled),
.u-status-candidate:not(.disabled),
.u-status-unset {
  background-color: $status-pending;
  color: $black;
}
.u-status-hover.u-status-pending:not(.disabled):hover,
.u-status-hover.u-status-candidate:not(.disabled):hover {
  background-color: $status-pending-secondary;
  color: $black;
}

.u-txt-status-in-progress,
.u-txt-status-submitted {
  color: $status-in-progress-secondary;
}
.u-status-in-progress:not(.disabled),
.u-status-submitted:not(.disabled),
.u-status-scheduled {
  background-color: $status-in-progress;
}
.u-status-hover.u-status-in-progress:not(.disabled):hover,
.u-status-hover.u-status-submitted:not(.disabled):hover {
  background-color: $status-in-progress-secondary;
}

.u-txt-status-completed,
.u-txt-status-passed {
  color: $status-completed-secondary;
}
.u-status-completed:not(.disabled),
.u-status-passed:not(.disabled) {
  background-color: $status-completed;
}
.u-status-hover.u-status-completed:not(.disabled):hover,
.u-status-hover.u-status-passed:not(.disabled):hover,
.u-status-facial-true {
  background-color: $status-completed-secondary;
}

.u-txt-status-overdue,
.u-txt-status-declined {
  color: $status-error-secondary;
}
.u-status-overdue:not(.disabled),
.u-status-declined:not(.disabled) {
  background-color: $status-error;
}
.u-status-hover.u-status-overdue:not(.disabled):hover,
.u-status-hover.u-status-declined:not(.disabled):hover,
.u-status-facial-false {
  background-color: $status-error-secondary;
}

.u-txt-status-completed-with-result {
  color: $status-completed-with-result-secondary;
}
.u-status-completed-with-result:not(.disabled) {
  background-color: $status-completed-with-result;
}
.u-status-hover.u-status-completed-with-result:not(.disabled):hover {
  background-color: $status-completed-with-result-secondary;
}

.u-txt-status-canceled {
  color: $status-canceled-secondary;
}
.u-status-canceled:not(.disabled) {
  background-color: $status-canceled;
}
.u-status-hover.u-status-canceled:not(.disabled):hover {
  background-color: $status-canceled-secondary;
}
