@use 'styles/mixins' as *;

.root {
  max-width: 500px;
}

.thumbnail {
  width: 100%;
  border-radius: 24px;
  border: 1px solid $base-grey-10;
}
