@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;
  background: $background-gradient;
  width: 100vw;
  height: 100vh;

  .card {
    @include flex-box-centered;
  }

  .content {
    width: 525px;
    padding: 60px;
  }

  .logo {
    @include flex-box-centered;
    flex-direction: column;
    max-width: 25rem;
    cursor: pointer;
  }

  .emailIllustration {
    max-width: 200px;
    margin: 1em 0;
  }
}
