@use 'styles/colors' as *;

.root {
  position: fixed;
  background-color: $white;
  box-shadow: 2px 4px 14px 0px $base-grey-60;
  width: 400px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px auto;
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: bottom 0.4s ease-in-out;
  padding: 15px 20px;
}

.hidden {
  bottom: -100px;
}

.badge {
  background-color: $brand-primary-120;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIcon {
  cursor: pointer;
  color: $brand-primary-120;
  margin: 0 7px;
}
