@use 'styles/mixins' as *;

.root {
  .badge {
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    padding: 2px 7px;
    font-weight: 500;

    & > svg {
      margin-right: 2px;
    }

    &--success {
      background-color: $green-10;
      color: $green-150;
    }

    &--average {
      background-color: $orange-20;
      color: $orange-150;
    }

    &--bad {
      background-color: $red-20;
      color: $red-150;
    }
  }

  .bar {
    position: relative;
    width: 100%;
    border: 1px solid $base-grey-10;
    height: 14px;
    background-color: $base-grey-5;
    border-radius: 14px;
    margin-bottom: 30px;

    &Progress {
      top: -1px;
      left: -1px;
      position: absolute;
      height: 14px;
      border-radius: 14px;
    }

    &PercentageTags {
      position: absolute;
      top: 20px;
      display: flex;
      justify-content: space-between;
      left: 0;
      right: 0;
    }

    &PercentageTag {
      position: relative;

      &::before {
        top: -4px;
        left: 50%;
        position: absolute;
        content: '';
        height: 6px;
        border-left: 1px solid $base-grey;
      }
    }

    &--success {
      .barProgress {
        background: linear-gradient(to right, #a3e2a8, #43a047);
      }
    }

    &--average {
      .barProgress {
        background: linear-gradient(to right, #eccaac, #f2994a);
      }
    }

    &--bad {
      .barProgress {
        background: linear-gradient(to right, #d6aeae, #eb5757);
      }
    }
  }

  .imageCardsContainer {
    display: flex;
    justify-content: space-between;
    gap: $margin-default;
  }

  .imageCard {
    margin-top: 7px;
    border-radius: 12px;
    border: $grey-border;
    width: 100%;
    height: 200px;
    background-color: $background-secondary;
    overflow: hidden;

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .detailsValues {
    margin-top: $margin-default;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: $margin-default;

    .detailValue {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
}
