@use 'styles/colors' as *;

.root {
  border: 1px solid $brand-primary-5;
  border-radius: 8px;
  width: 32%;
  padding: 1em;
  overflow: hidden;
  background-color: $white;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  color: $brand-primary-160;
  margin-bottom: 4px;
  font-weight: 600;

  svg {
    height: 17px;
    margin-right: 5px;
  }
}

.link {
  padding-left: 4px;
  color: $brand-primary;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $brand-primary-120;
  }
}

.value {
  padding-left: 4px;
  color: $brand-primary-160;
}

.alertMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;

  &Warn {
    color: $neon-carrot;
  }
  &Error {
    color: $status-error-secondary;
  }
  &Success {
    color: $status-completed-secondary;
  }

  svg {
    height: 13px;
    margin-right: 1px;
  }
}
