@use 'styles/mixins' as *;

.root {
  background-color: $base-grey-5;
  min-height: 100vh;
}

.header {
  padding: 30px 30px 25px 30px;
  background-color: $white;

  .candidateDetails {
    flex-wrap: wrap;
    gap: 20px;

    @include viewportNotXLarge {
      flex-wrap: nowrap;
    }

    .infoFieldRow {
      display: grid;
      align-content: center;
      align-items: flex-start;
      justify-items: stretch;
      grid-auto-flow: column;
      row-gap: 20px;

      @include viewportSmall {
        grid-template-rows: repeat(2, 1fr);
      }
    }

    .infoField {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-right: 30px;

      p {
        margin: 0;
      }

      .title {
        text-transform: uppercase;
        color: $brand-primary-160;
        font-size: 10px;
        font-weight: 500;
      }

      .value {
        color: $brand-primary-160;
        font-weight: 500;

        .time {
          font-size: 12px;
        }
      }
    }
  }
}

.name {
  font-weight: 800;
  margin: 0;
}

.icon {
  margin-left: 0.7em;
}

.tags {
  margin-top: 20px;
}

.tag {
  margin-right: 10px;
  background-color: $brand-primary-80;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  .tagDeleteBtn {
    width: 0;
    height: 15px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &:hover {
    .tagDeleteBtn {
      margin-left: 10px;
      width: 15px;
    }
  }
}

.badge {
  height: 30px;
  align-content: center;
  border-radius: 15px;
}

.arrowIcon {
  margin: 8px;
  height: 40px;
  width: 40px;
  border-width: 2px;
}

.addModalCheck {
  max-width: 450px;
}

.emailLinkContainer {
  position: relative;
}

.hideMedium {
  @include viewportMedium {
    display: none !important;
  }
}

.showMedium {
  @include viewportNotMedium {
    display: none !important;
  }
}

.hideMobile {
  @include viewportMobile {
    display: none !important;
  }
}

.selectDropdown {
  padding: 10px 30px 20px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
}

.dropdownTabContent {
  display: flex;
  padding: 20px 30px 10px 30px;
}
