@use 'styles/mixins' as *;

.root {
  display: flex;
  justify-content: center;

  .body {
    p {
      color: $secondary-text-color;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0.7em;
    }
  }
}
