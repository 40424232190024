@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;
  background: $background-gradient;
  width: 100vw;
  height: 100vh;

  .card {
    @include flex-box-centered;
  }

  .content {
    width: 500px;
    padding: 60px;
  }

  .logo {
    @include flex-box-centered;
    flex-direction: column;
    max-width: 25rem;
    max-height: 10rem;
    cursor: pointer;
  }

  .errorWrapper {
    @include flex-box-centered;
    flex-direction: column;
    background-color: $error-color-background;
    width: 80%;
    color: $white;
    padding: 2rem;
    margin-top: 1rem;

    @include viewportMobile {
      min-width: 100%;
    }

    p,
    h2 {
      color: $white;
    }

    p {
      margin-top: 2rem;
    }
  }

  .scopes {
    margin: 1em 0;
    width: 100%;

    li {
      padding: 1em 0;

      &:not(:last-of-type) {
        border-bottom: $grey-border;
      }
    }
  }
}
