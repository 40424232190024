@use 'styles/mixins' as *;

.root {
  border-radius: 24px !important;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.tile {
  height: 150px;
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--primary {
    .badge {
      background-color: $brand-primary-5;
      color: $brand-color-dark;
    }
  }

  &--candidate {
    .badge {
      color: $yellow;
      background-color: $yellow-20;
    }
  }

  &--in_progress {
    .badge {
      color: $blue;
      background-color: $blue-20;
    }
  }

  &--overdue {
    .badge {
      color: $red;
      background-color: $red-20;
    }
  }

  &--completed {
    .badge {
      color: $green;
      background-color: $green-10;
    }
  }

  &--canceled {
    .badge {
      color: $base-grey;
      background-color: $base-grey-10;
    }
  }

  &--warning {
    .badge {
      color: $orange-150;
      background-color: $orange-20;
    }
  }
}

.divider {
  height: 150px;
  width: 0px;
  border-right: $grey-border;
}

.value {
  margin: 0;
  color: $brand-primary-200;
  font-size: 48px;
  font-weight: 700;
}

.badge {
  text-transform: capitalize !important;
  font-weight: 500;
  border-radius: 1000px;
}

.subtitle {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: $brand-primary-160;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between !important;
  height: 100%;
}
