.root {
  position: fixed;
  background-color: rgba(46, 38, 62, 0.4);
  overflow: hidden;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  &Visible {
    display: block;
  }

  &Large {
    .child {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .child {
    overflow-y: auto;
    position: relative;
    padding: 40px;
    background-color: #fff;
    box-shadow: 2px 4px 14px 0px #868686;
    border-radius: 8px;
    max-height: 90%;
    max-width: 95%;
  }

  .closeBtn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: 30px;
    color: #d4d1d1;
    z-index: 2;
    height: auto;
  }
}
