@use 'styles/mixins' as *;

.root {
  position: relative;
  @include flex-box-centered;
  @include input;
  height: 10em;
  min-width: 300px;

  &:hover {
    .removeFileIconWrapper {
      opacity: 1;
    }
  }

  svg {
    color: $base-grey-50;
  }
}

.fileWrapper {
  @include flex-box-centered;
  flex-direction: column;
}

.dropzone {
  @include flex-box-centered;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.placeholder {
  color: $base-grey-35;
}

.icon {
  color: $base-grey-35;
}

.removeFileIconWrapper {
  @include flex-box-centered;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  background-color: #6b6b6b6e;
  cursor: pointer;
  transition: opacity 0.4s;

  .removeFileIcon {
    padding: 10px;
    background-color: $error-color;
    border-radius: 50%;
    color: $white;
  }
}
