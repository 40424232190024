@use 'styles/colors' as *;

.details {
  background-color: $white;
  padding: 2em;
}

.filtersModalForm {
  width: 300px;
}
