@use 'styles/variables' as *;

.root {
  tr {
    cursor: pointer;
    border-bottom: $grey-border;
    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: $base-grey-10;
    }
  }

  td {
    padding: 10px 20px;

    ul {
      padding-left: 0;

      &:not(:last-of-type) {
        padding-bottom: 15px;
        border-bottom: $grey-border;
      }
    }
  }

  .key {
    font-weight: 600;
    text-transform: capitalize;
  }
}
