.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .search {
    max-width: 400px;
    width: 50%;
  }

  .showing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .filter {
    width: 200px;
    margin-right: 15px;
  }
}
