@use 'styles/mixins' as *;

.root {
  position: relative;
  margin: 15px 0 20px 0;
}

.card {
  @include flex-box-justify;

  width: 400px;
  min-height: 70px;
  border-radius: 8px;
  background-color: $white;
  border: dashed 1px $brand-primary-5;
  padding: 15px;
  cursor: pointer;

  &:hover {
    .icon {
      background-color: $brand-primary;
      color: $brand-primary-5;
    }
  }

  span {
    font-size: 18px;
    color: $brand-primary-160;
    margin: 0;
    font-weight: 500;
    margin-right: 10px;
  }
}
