@use 'styles/colors' as *;

.userNameColumn {
  display: flex;
  align-items: center;
}

.starIcon {
  color: $brand-color-dark;
  margin-right: 7px;
  margin-top: 2px;

  &.selected {
    fill: $brand-color-dark;
  }

  &:not(.selected) {
    cursor: pointer;
  }
}
