.root {
  display: flex;
  justify-content: center;
  padding: 30px;
  background-color: #f3f4f5;
  min-height: 100vh;

  .refereeReport {
    margin-top: 100px;
  }
}
