@import 'styles/mixins';

.blockTitle {
  @include text-h5;
  grid-column: 1 / span 11;
  width: 100%;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;

  .subTitle {
    margin-top: 0.3rem !important;
  }

  span {
    margin-right: 0.5rem;
  }
}

.marginBottom {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}
