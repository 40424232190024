@use 'styles/mixins' as *;

.root {
  background-color: $base-grey-5;
  min-height: 100vh;
}

.filters {
  display: flex;
  align-items: flex-end;
  padding: 0 50px 40px 50px;
  background-color: $white;
}

.results {
  min-height: 70vh;
}

.columnTitles {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 1em 1em 0 1em;
  color: $brand-primary-160;
}

.columnHeader {
  padding: 0px 50px 0 ;
  background-color: white;
  border-bottom: $grey-border;
}

.column {
  width: 19%;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
}

.emptyResults {
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 25px;
    width: auto;
  }

  p {
    margin: 0 15px;
    color: $brand-primary-160;
    font-size: 24px;
  }
}

.checksTable {
  padding: 1em 0 5em 0;
}

.refreshIcon {
  @include flex-box-centered;
  margin-bottom: 10px;

  svg {
    height: 20px;
  }
}

@media (max-width: 1000px) {
  .smHide {
    display: none !important;
  }

  .columnTitles {
    padding: 0 1em;
  }

  .checksTableHeader {
    width: 30%;
  }
}

@media (max-width: 700px) {
  .xsHide {
    display: none !important;
  }

  .header {
    padding: 10px;
  }

  .columnTitles {
    padding-top: 2em;
  }
}

@media (max-width: 1250px) {
  .columnTitles {
    padding: 1em 2em;
  }
}

@media (max-width: 1100px) {
  .columnTitles {
    padding: 1em 4em;
  }

  .column {
    width: 30%;
  }
}
