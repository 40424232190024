.root {
  min-height: 100vh;
  background-color: #fff;

  .content {
    padding-top: 70px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .accountLogo {
    width: 15%;
    margin-bottom: 80px;
  }

  .extraQuestion {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
      text-align: center;
      font-size: 25px;
      color: #2e263e;
    }

    .buttonsOptions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      gap: 20px;

      .button {
        width: 130px;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .button {
    margin: 0 15px;
  }
}
