.chart {
  &Tooltip {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    padding: 5px;

    &Label {
      @include text-small;
      color: $white;
      margin: 0;
    }

    &Value {
      @include text-small;
      color: $white;
      font-weight: bold;
      margin: 0;
    }
  }
}

.recharts-label {
  font-size: 12px;
  font-family: inter, sans-serif;
}
