@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;
  background: $background-gradient;
  width: 100vw;
  height: 100vh;

  .card {
    overflow: scroll;
    max-height: 90vh;
  }

  .content {
    width: 500px;
    padding: 60px;
  }

  .logo {
    @include flex-box-centered;
    flex-direction: column;
    max-width: 25rem;
    cursor: pointer;
  }

  .divider {
    position: relative;
    margin: 1.5em 0;
    width: 100%;

    p {
      margin: 0;
      padding: 0 1em;
      position: absolute;
      top: 0;
      left: 45%;
      background-color: $white;
    }

    hr {
      margin: 11px 0px 10px 0;
      color: $brand-primary-5;
    }
  }

  .forgotPassword {
    position: absolute;
    top: -0.6rem;
    right: 0;
    font-size: 1.1rem;
  }
}
