@use 'styles/mixins' as *;

.poweredBy {
  img {
    width: 150px;
  }
}

.name {
  .label {
    @include text-small;
    text-transform: uppercase;
  }

  .value {
    @include text-h3;
  }
}

.infoField {
  .label {
    @include text-tiny;
    text-transform: uppercase;
  }
}
