@use 'styles/colors' as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
.title {
  color: $brand-primary-200;
  font-size: 18px;
  font-weight: 700;
}
.subtitle {
  color: $brand-primary-160;
  font-size: 13px;
  font-style: italic;
}

.row {
  margin-bottom: 15px;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $brand-primary-80;
    top: 17px;
    left: 12px;
  }

  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 25px);
    border-left: 1px dotted $brand-primary-80;
    top: 20px;
    left: 19px;
    z-index: 99;
  }
}
