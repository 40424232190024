@use 'styles/colors' as *;

.content {
  display: flex;
  width: 100%;

  & > * {
    width: 50%;
  }
}

.details {
  background-color: $white;
  padding: 2em;
}

.section {
  padding: 28px;

  .checks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    align-items: flex-start;
  }

  .title {
    grid-column: 1 / span 10;
    font-size: 20px;
    margin: 0;
    display: flex;
    justify-self: start;
  }
}

.toogleButton {
  cursor: pointer;
  margin-left: 0.5em;
  transform: rotate(180deg);
  transition: all 0.4s ease-in-out;

  &Opened {
    transform: rotate(0);
  }
}
