@use 'styles/mixins' as *;

.root {
  position: relative;
  @include flex-box-centered;
  @include input;
  height: 10em;
  min-width: 300px;
  border-radius: 10px;

  &:hover {
    @include pseudo-states() {
      border-color: var(--button-color);
    }

    .removeFileIconWrapper {
      opacity: 1;
    }
  }

  svg {
    color: $base-grey-50;
  }
}

.browseButton {
  color: var(--link-color);
}

.fileWrapper {
  @include flex-box-centered;
  flex-direction: column;
}

.dropzone {
  @include flex-box-centered;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.placeholder {
  color: $base-grey-35;
}

.icon {
  color: $base-grey-35;
}

.removeFileIconWrapper {
  @include flex-box-centered;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  background-color: #6b6b6b6e;
  cursor: pointer;
  transition: opacity 0.4s;

  .removeFileIcon {
    padding: 10px;
    background-color: $error-color;
    border-radius: 50%;
    color: $white;
  }
}

.uploadLine {
  margin-top: 10px;
  width: 100%;
  border: 1px solid $base-grey-10;
  background-color: $background-secondary;
  padding: 5px 7px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .uploadName {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 5px;
    }
  }

  .uploadDeleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    width: 29px;
    height: 29px;
    cursor: pointer;

    & > svg {
      color: #ff4949;
    }
  }
}
