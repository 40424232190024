@use 'styles/colors' as *;

.root {
  padding: 3em;
  width: 100%;
  background-color: $white;

  img {
    height: 30px;
    margin-bottom: 2em;
    cursor: pointer;
  }
}
