.root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #2e263e;

  .top,
  .bottom {
    text-align: center;
  }

  .top {
    margin-bottom: 75px;

    .text {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .bottom {
    img {
      height: 30px;
    }
  }
}
