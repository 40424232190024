@use 'styles/colors' as *;

.configuration {
  display: flex;
  flex-direction: column;

  .header {
    font-size: 20px;
    font-weight: bold;
    color: $brand-primary-200;
  }

  .description {
    margin: 15px 0;
    color: $base-grey-80;
  }

  .step {
    display: flex;
    margin-top: 15px;
  }

  .stepOrder {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: $brand-primary-80;
    color: $white;
    margin-right: 10px;
  }

  .qrWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .secretCode {
    font-weight: 600;
    font-size: 12px;
    margin: 15px 0;
    color: $brand-primary-160;
  }
}

.configured {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    background-color: $status-completed-secondary;
    color: $white;
    padding: 10px 30px;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkIcon {
      height: 20px;
    }
  }

  .disabledButton {
    background-color: $status-error;
    color: $white;

    &:hover {
      background-color: $status-error-secondary;
    }
  }
}

.qrImage {
  width: 300px;
  height: 300px;

  img {
    width: 300px;
    height: 300px;
  }
}
