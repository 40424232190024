@import 'styles/colors';

.input {
  width: 300px;
  margin-right: 15px;
  margin-bottom: 0;
}

.columnsTable {
  th,
  td {
    padding: 2px 5px;
    border: 1px solid $base-grey-10;
  }

  th {
    background-color: $brand-primary-20;
  }

  td {
  }
}
