@use 'styles/mixins' as *;

.root {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $base-grey-5;

  &_logo {
    position: absolute;
    top: 10px;
    left: 10px;
    min-width: 40px;
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
    z-index: 99;

    @include viewportMobile {
      min-width: 20px;
      max-width: 40px;
      max-height: 40px;
    }
  }

  @include viewportMobile {
    background-color: $white;
  }
}

.content {
  margin: 60px 0;
  width: 700px;
  max-width: 90%;

  &FullScreen {
    margin: 0;
    max-width: 100%;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
  }

  @include viewportMobile {
    max-width: 100%;
    margin: 20px;
  }
}

.card {
  min-width: 500px;

  @include viewportMobile {
    min-width: 300px;
    border: none !important;
    border-radius: 0 !important;
    width: 100%;
  }
}

.logo {
  min-width: 40px;
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 100%;
}

:global(.branded-text) {
  a {
    color: var(--link-color);
  }
}
