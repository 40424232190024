@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    min-width: 40px;
    max-width: 70px;
    max-height: 70px;
  }

  .idsWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    column-gap: 15px;
    row-gap: 15px;
    justify-content: center;
  }
}
