@use 'styles/colors' as *;

.root {
  width: 100%;

  .clear_bundle {
    font-size: 12px;
    color: $brand-primary;
    cursor: pointer;
    transition: color 0.4s;
    -webkit-transition: color 0.4s;
    font-weight: 500;
    text-decoration: underline;
  }
}

.sectionTitle {
  color: $brand-primary-160;
  font-size: 13px;
  margin-bottom: 1em;
}
