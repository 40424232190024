@use 'styles/mixins' as *;

.root {
  .title {
    color: $brand-primary-200;
    font-size: 18px;
    font-weight: 700;
  }

  .subtitle {
    color: $brand-primary-200;
    font-size: 13px;
    font-style: italic;
  }

  .content {
    margin-top: 30px;
    display: flex;
  }

  .cards {
    width: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    margin-right: 50px;
  }
  .divider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid $brand-primary-5;
    margin: 8px 0;
  }
}
