/////////////////////////////////// COLORS /////////////////////////////////////

/***** BRAND *****/
$brand-primary-1: #fbf9fe;
$brand-primary-5: #e5dff2;
$brand-primary-15: #e4dde4;
$brand-primary-20: #ddd5ef;
$brand-primary-25: #b5abca;
$brand-primary-40: #8b8398;
$brand-primary-70: #d4bfff;
$brand-primary-80: #ae86ff;
$brand-primary-90: #9566e5;
$brand-primary: #7a48df;
$brand-primary-105: #6e41d3;
$brand-primary-120: #5922be;
$brand-primary-160: #514779;
$brand-primary-200: #2d264d;

/***** PINK *****/
$brand-pink-90: #ff95ff;
$brand-pink: #d257d2;

/***** STATUSES *****/
$status-pending: #ffd35d;
$status-pending-secondary: #c5a03a;
$status-in-progress: #74b0ff;
$status-in-progress-secondary: #366db4;
$status-completed: #61e48c;
$status-completed-secondary: #2ebf5e;
$status-error-background: #f7e6e6;
$status-error: #bd4e4e;
$status-error-secondary: #ad3131;
$status-completed-with-result: #cf5a00;
$status-completed-with-result-secondary: #e67017;
$status-canceled: #5a5e62;
$status-canceled-secondary: #7e7e7e;

/***** LINK *****/
$link-1: rgba(255, 215, 255, 0.5);
$link-soft: #ffd7ff;
$link: #ff95ff;
$link-hover: #fc80fc;
$link-disabled: #915e91;

/***** GREYS *****/
$black: #000000;
$white: #ffffff;
$base-grey-2: #f4f7fa;
$base-grey-3: #f9f9f9;
$base-grey-12: #e5e5e5;
$base-grey-15: #dbdee1;
$base-grey-20: #d0d3d7;
$base-grey-25: #c7cacd;
$base-grey-40: #a9acb0;
$base-grey-60: #7e7e7e;
$base-grey-80: #5a5e62;
$base-grey-90: #666666;
$base-grey: #33373b;
$base-grey-110: #313131;

/***** COLORS *****/
$electric-indigo: #9013fe;
$true-v: #865fc1;
$royal-blue: #4a90e2;
$maya-blue: #46c4f3;
$dodger-blue: #1f8ceb;
$turquoise: #50e2da;
$viking: #3bb7bb;
$sinbad: #9fd0c3;
$lemon: #f3e108;
$gold: #ffd700;
$lightning-yellow: #f5a623;
$neon-carrot: #ff8736;
$red: #bd4e4e;
$pumpkin: #f56023;
$supernova: #ffb72c;
$lemon-chiffon: #fff6bf;
$golden-brown: #ad7313;
$varden: #fdebcc;
$buddha-gold: #bf821b;
$venetian-red: #d0021b;
$primrose-garden: #eb4986;
$free-speech: #e468c7;
$pale-pink: #fbe3e4;
$pink-lace: #f4c4ca;
$pink: #ff95ff;
$cerise: #c62161;
$light-coral: #ec7c92;
$alice-blue: #e5f2fd;
$ghost: #cbccce;
$lime: #2ec45f;
$spring-sun: #e6efc2;
$nepal: #97b0bd;
$pacific-blue: #0099c2;
$raven: #6b758c;
$purple-pizzazz: #da00da;

/***** NEW *****/
$brand-color-light: #ae86ff;
$brand-color: #7a48df;
$brand-color-dark: #5922be;

$red: #f13d15 !default;
$red-20: rgba(241, 61, 21, 0.2) !default;
$red-150: #9f2307 !default;
$green-150: #02722f;
$green: #039d41;
$green-10: rgba(46, 191, 94, 0.1);
$yellow: #dfbf0b;
$yellow-20: rgba(224, 192, 11, 0.2);
$blue: #2f80ed;
$blue-20: rgba(47, 129, 237, 0.2);
$orange-150: #f2994a;
$orange-20: rgba(242, 153, 74, 0.2);

$base-grey-5: #f9f9f9 !default;
$base-grey-10: #e5e5e5 !default;
$base-grey-25: #999999 !default;
$base-grey-35: #777777 !default;
$base-grey-50: #666666 !default;
$base-grey: #333333 !default;
