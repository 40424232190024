@use 'styles/variables' as *;

.root {
  position: fixed;
  bottom: 0;
  min-width: 100vw;
  text-align: center;
  font-size: 14px;
  padding: 15px 0;
  border-bottom: $grey-border;
  color: $base-grey-60;
  font-weight: 500;
  background-color: $white;
  z-index: 99;
  box-shadow: 0px 0px 5px $base-grey-20;
}
