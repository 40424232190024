@import 'styles/mixins';

@mixin icon {
  position: absolute;
  color: $base-grey-50;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
}

.root {
  max-width: 100% !important;
  margin-bottom: 15px;
}

.header {
  @include text-body;
  background-color: $base-grey-5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $base-grey;
  border-radius: 10px;

  .iconWrapper {
    position: relative;
    height: 1.8rem;
    width: 1.8rem;

    .editIcon {
      @include icon;
      opacity: 1;
      z-index: 2;
      transition: opacity 0.2s 0.4s;
    }

    .chevronIcon {
      @include icon;
      opacity: 0;
      z-index: 1;
      transform: rotateY(0deg);
      transition:
        transform 0.4s,
        opacity 0.4s 0.4s;
    }
  }

  &Visible {
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $base-grey-10;

    .iconWrapper {
      .chevronIcon {
        opacity: 1;
        z-index: 2;
        transform: rotate(180deg);
        transition:
          transform 0.4s,
          opacity 0.4s 0s;
      }

      .editIcon {
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s 0s;
      }
    }
  }
}

.body {
  padding: 1rem;

  div:last-of-type {
    margin-bottom: 0 !important;
  }
}
