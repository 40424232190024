@use 'styles/colors' as *;

.root {
  background-color: $base-grey-5;
  min-height: 100vh;

  .tabs {
    .iqTitle {
      img {
        height: 12px;
        margin-bottom: 2px;
      }
    }
  }
}
