@use 'styles/mixins' as *;

.idsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;

  @include viewportMobile {
    grid-template-columns: 1fr;
  }

  .idButton {
    display: flex;
    padding: 0 10px;

    .idButtonText {
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }
  }
}

.frameContainer {
  position: relative;
  width: 400px;
  border-radius: 10px;
  height: 600px;
  overflow: hidden;
  z-index: 999;

  @include viewportMobile {
    height: 100vh;
    margin-top: -40px;
    margin-bottom: -40px;
    width: 100vw;
    border-radius: 0;
  }

  .image {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    margin: auto;

    @include viewportMobile {
      border-radius: 0;
    }
  }

  .buttons {
    width: 95%;
    left: 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 15px;
  }
}
