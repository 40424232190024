@use 'styles/mixins' as *;

@mixin selector {
  width: 15px;
  height: 15px;
  margin-right: 0.7em;
  transition: all 0.2s ease-in-out;
  border: 1px solid $base-grey-10;
}

.root {
  .option {
    @include text-body;
    display: flex;
    align-items: center;
    color: $base-grey;
    background-color: $base-grey-3;
    border: 1px solid $base-grey-10;
    border-radius: 6px;
    padding: 0.8em 1.4em;
    transition: border-color 0.4s ease-in-out;
    cursor: pointer;
    margin-bottom: 1em;

    .checkbox {
      @include flex-box-centered;
      @include selector;
      background-color: transparent;
      border-radius: 4px;

      .checkIcon {
        color: $white;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
      }
    }

    .radioButton {
      @include selector;
      position: relative;
      border-radius: 50%;

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        background-color: $base-grey-10;
      }
    }

    &:hover {
      border-color: $brand-color;
    }

    &Selected {
      border-color: $brand-color;

      .checkbox {
        background-color: $brand-color;
        border-color: $brand-color;

        .checkIcon {
          opacity: 1;
        }
      }

      .radioButton {
        border-color: $brand-color;

        &::before {
          background-color: $brand-color;
        }
      }
    }
  }

  .otherOption {
    display: block;

    .title {
      display: flex;
    }

    .input {
      margin-top: 1em;

      input {
        background-color: $white;
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    &:hover {
      border-color: $base-grey-10;
    }
  }

  &RadioButton {
    .option {
      border: none;
      background-color: transparent;
      padding: 0;
      margin-bottom: 0.5em;
    }
  }
}
