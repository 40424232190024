@use 'styles/variables' as *;

.root {
  padding: 20px 50px 0;
}

.checkRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em;
  border-radius: 15px;
  background-color: $white;
  transition: border-radius 0.4s;
  -webkit-transition: border-radius 0.4s;
  border: $grey-border;

  &.--opened {
    border-radius: 15px 15px 0 0;
  }
}

.column {
  width: 19%;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-top: 7px;
}

.userIcon {
  position: relative;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 16px;
  }

  .resultIcon {
    width: 10px;
    height: 10px;
    position: absolute;
    color: $status-error;
    z-index: 2;
    top: -5px;
    right: -5px;
  }

  .approvalIcon {
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 2;
    top: -5px;
    right: -5px;

    &_NotApproved {
      color: $status-error-secondary;
    }

    &_Approved {
      color: $status-completed-secondary;
    }

    &_NeedReview {
      color: $status-pending-secondary;
    }
  }
}

.candidateLink {
  cursor: pointer;
  color: $brand-primary;
  text-decoration: underline;
  transition: color 0.4s;
  -webkit-transition: color 0.4s;

  &:hover {
    color: $brand-primary-105;
  }
}

.iconsContainer {
  display: flex;
  margin-left: 2em;
  position: relative;
}

.checkContent {
  overflow: hidden;
  width: 100%;
  padding: 0;
  background-color: $brand-primary-1;
  border-radius: 0 0 15px 15px;

  &.candidateLink {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkTableRow {
  &:nth-child(odd) {
    background-color: $brand-primary-1;
  }
  &:nth-child(even) {
    background-color: $white;
  }
}

.gap {
  row-gap: 7px;
  column-gap: 7px;
}

@media (max-width: 1250px) {
  .checkTableRow {
    padding: 1em 2em;
  }
  .checkRow {
    padding: 1em 2em;
  }
  .checkContent {
    padding: 0 2em;
  }
}
@media (max-width: 1100px) {
  .checkTableRow {
    padding: 1em 0;
  }
  .checkRow {
    padding: 1em 4em;
    border-radius: 0;
  }
  .checkContent {
    padding: 0 4em;
  }
  .column {
    width: 30%;
  }
}
