@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    min-width: 40px;
    max-width: 70px;
    max-height: 70px;
  }

  .document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    &Logo {
      width: 60px;
      height: 60px;
    }

    &Instructions {
      max-height: 50vh;
      overflow: scroll;
    }
  }
}
