@font-face {
  font-family: 'inter';
  src:
    local('inter'),
    url(./../assets/fonts/Inter.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src:
    local('inter'),
    url(./../assets/fonts/Inter-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'urw-gothic';
  src:
    local('urw-gothic'),
    url(./../assets/fonts/URWGothic-Demi.ttf) format('truetype');
}
