@use 'styles/colors' as *;

.codeInput {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  width: 300px !important;

  input,
  input:last-child,
  input:first-child,
  input:focus {
    font-family: 'Montserrat', sans-serif !important;
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid $brand-primary-160 !important;
    margin-right: 10px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 0 !important;
    caret-color: $brand-primary-160 !important;
  }
}

.character {
  font-family: 'inter', sans-serif;
  border: 0;
  border-bottom: 2px solid $brand-primary-160;
  outline: none;
  color: $brand-primary-160;
}

.characterInactive {
  background-color: transparent;
}
