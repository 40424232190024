@use 'styles/colors' as *;

.root {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: $base-grey-5;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px;
  overflow: scroll;
}
