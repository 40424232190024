@use 'styles/colors' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    max-width: 70px;
    max-height: 70px;
    min-width: 40px;
  }
}
