@use 'styles/variables' as *;

.root {
  margin: 0 15px;
  position: relative;

  .button {
    height: 40px !important;
    width: 120px !important;
    display: flex;
    justify-content: space-around;
    background-color: $white !important;
    color: $primary-text-color !important;
    border: 1px solid $base-grey-12 !important;
    transition: all 0.4s;

    &:hover {
      background-color: $white !important;
      color: $primary-text-color !important;
      border: 1px solid $brand-primary !important;
    }

    .chevron {
      margin-right: 0;
      color: $brand-primary-200;
    }
  }

  .dropdownMenu {
    position: absolute;
    min-height: 50px;
    width: 340px;
    padding: 10px;
    background-color: $white;
    border: 1px solid $brand-primary-5;
    top: calc(100% + 5px);
    right: 0;
    z-index: 99;
    border-radius: 8px;
    max-height: 80vh;
    overflow: scroll;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    .checkRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0px;
      font-size: 12px;

      &:not(:last-of-type) {
        border-bottom: 1px solid $base-grey-2;
      }

      &Logo {
        height: 30px;
        margin-right: 10px;
      }

      &Credits {
        font-weight: 500;
        &Unlimited {
          color: $status-completed-secondary;
        }

        &Zero {
          color: $status-error-secondary;
        }
      }
    }
  }
}
