@use 'styles/mixins' as *;

.root {
  .menu {
    top: 120%;
    position: absolute;
    z-index: 99;
    margin: 0;
    right: 0;
    list-style-type: none;
    padding-inline-start: 0;
    min-width: initial;
    overflow: hidden;
    height: initial;
    max-height: 0;
    border-width: 0;
    transition: all 0.4s ease-in-out;

    .item {
      @include text-body;
      padding: 0.5em 1em;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.4s ease-in-out;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $base-grey-10;
      }

      &Disabled {
        color: $base-grey-60;
        cursor: not-allowed;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  &Opened {
    .menu {
      padding: 0.5em 0;
      max-height: 30em;
      border-width: 1px;
    }
  }
}
