@use 'styles/mixins' as *;

.root {
  @include viewportMobile {
    position: inherit;
  }
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step {
  @include viewportMobile {
    height: 100%;
  }
  position: absolute;
  width: 100%;
  background: #f9f9f9;
  padding-top: 0px;
  transition:
    transform 600ms ease 0s,
    opacity 300ms ease 0s;
  margin-top: 120px;
  margin-bottom: 125px;

  &Before {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-100vh);
  }

  &After {
    pointer-events: none;
    opacity: 0;
    transform: translateY(100vh);
    @include viewportMobile {
      display: none;
    }
  }
}

.navigation {
  margin-top: 4rem;
  @include flex-box-justify;
}
