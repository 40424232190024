@use 'styles/variables' as *;

.root {
  border-color: transparent;
  border-radius: 0;
  height: 56px;
  opacity: 1;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 1000;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $base-grey-3;
  border-bottom: 1px solid $base-grey-12;

  &NotScrolled::after {
    opacity: 0;
  }

  .left {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logo {
      cursor: pointer;
      height: 105%;
      margin-bottom: 5px;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 50px;

      a {
        font-family: urw-gothic;
        color: $secondary-text-color;
        font-size: 15px;
        background-color: transparent;
        cursor: pointer;
        margin: 0 15px;
        text-decoration: none;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -80%;
          left: 0;
          background-color: $brand-primary;
          height: 0;
          width: 100%;
          transition: 0.2s all;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          opacity: 0;
        }

        &:hover,
        &:global(.active) {
          color: $primary-text-color;

          &::after {
            opacity: 1;
            height: 4px;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
}

@media (max-width: 600px) {
  .root {
    padding: 15px 20px;

    .hideXS {
      display: none !important;
    }
  }
}
