@use 'styles/mixins' as *;

.root {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  position: relative;
  padding: 0 50px 50px 50px;

  .errorCode {
    font-size: 300px;
    font-weight: 600;
    color: #ffdfff;
  }

  .anim {
    position: absolute;
    top: 130px;
    width: 500px;
    margin-left: 30px;
  }

  .message {
    margin-top: 170px;
    color: #2e263e;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }

  p {
    text-align: center;
    padding-top: 20px;
    color: #2e263e;

    a {
      color: #7a40e2;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;

    .button {
      height: 45px;
      width: 200px;
      margin: 10px;
    }
  }
}

/* Fucking IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .error-page .anim {
    position: absolute;
    top: 130px;
    left: 37%;
    width: 500px;
    margin-left: 0;
    height: 500px;
  }
}
