@use 'styles/mixins' as *;

.root {
  max-width: 450px;

  .logo {
    max-width: 40px;
    margin-right: 10px;
  }

  .content {
    padding-top: 15px;
  }
}
