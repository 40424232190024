@use 'styles/variables' as *;

.root {
  position: fixed;
  background-color: transparent;
  overflow: hidden;
  z-index: 9999;
  bottom: -200%;
  right: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  transition:
    visibility 0.3s ease-in-out,
    bottom 0.3s ease-in-out,
    background-color 0.3s ease-in-out 0.1s;

  &Visible {
    background-color: $overlay-color;
    visibility: visible;
    bottom: 0;
  }
}

.card {
  width: 600px;
  max-width: 90%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2.4rem 2.8rem;
  width: 100%;

  .textDiv {
    width: 80%;
    margin-bottom: 2rem;

    .title {
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  .form {
    div {
      margin-bottom: 0;
    }

    .input {
      input {
        color: $red;
        &:hover {
          border-color: $red;
        }
      }
    }
  }

  .label {
    span {
      span {
        color: $red;
      }
    }
  }
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;

  border-top: 1px solid $base-grey-10;
}

.subTitle {
  text-align: left;
}
