@use 'styles/colors' as *;

.checks {
  display: flex;
  align-items: center;

  .check {
    margin-right: 5px;
  }
}
