.root {
  cursor: pointer;
  width: 19%;

  &.asc::after {
    padding-left: 0.8em;
    font-size: 9px;
    content: '\25BC';
  }

  &.desc::after {
    content: '\25B2';
    padding-left: 0.8em;
    font-size: 9px;
  }
}
