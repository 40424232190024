@use 'styles/mixins' as *;

.root {
  & > input {
    display: none;
  }

  :global(.react-datepicker-popper) {
    z-index: 99;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container) {
    input {
      @include input;
    }
  }

  :global(.react-datepicker) {
    font-size: 12px;
  }

  :global(.react-datepicker__header) {
    :global(.react-datepicker__current-month) {
      font-size: 13px;
      line-height: 21px;
    }
  }

  :global(.react-datepicker__day-name) {
    font-size: 11.2px;
    width: 23.8px;
    line-height: 23.8px;
    margin: 2.3px;
  }

  :global(.react-datepicker__day-name),
  :global(.react-datepicker__day) {
    width: 23.8px;
    line-height: 23.8px;
    margin: 2.3px;
  }
  :global(.react-datepicker__day--selected) {
    background-color: $brand-color;
  }
  :global(.react-datepicker__month-select),
  :global(.react-datepicker__year-select) {
    outline: none;
    border-radius: 5px;
    border: $grey-border;
    padding: 2px;
  }

  &Invalid {
    :global(.react-datepicker__input-container) {
      input {
        border-color: $error-color;

        @include pseudo-states() {
          border-color: $error-color;
        }
      }
    }
  }
}
