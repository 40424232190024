@use 'styles/mixins' as *;

.root {
  position: relative;

  .button {
    @include input;
    color: $base-grey-50;
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 5px;
    }
  }
}

// Wrapper for the calendar
:global(.calendar-wrapper) {
  color: $brand-primary-160 !important;
  position: absolute;
  overflow: hidden;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 10;
}

// Calendar
:global(.react-calendar) {
  width: 275px;
  font-family: inter, sans-serif;
  border: none;
}

// Header (navigation wrapper)
:global(.react-calendar__navigation) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

// Month / Year label
:global(.react-calendar__navigation__label) {
  background-color: $white !important;
  font-weight: 600;
  font-size: 15px;
  flex-grow: 0 !important;
}

// Navigation Arrows
:global(.react-calendar__navigation__arrow) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-secondary !important;
  border: $grey-border !important;
  border-radius: 4px;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
}

:global(.react-calendar__month-view__weekdays) {
  padding: 0 10px;
}

// Weekdays
:global(.react-calendar__month-view__weekdays__weekday) {
  padding: 0 !important;

  & abbr {
    cursor: default;
    border-bottom: none;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
  }
}

:global(.react-calendar__month-view__days) {
  border-top: $grey-border;
  row-gap: 5px;
  margin-top: 10px;
  padding: 10px;
  background-color: $background-secondary;
}

:global(.react-calendar__month-view__days__day) {
  padding: 10.3px 5px !important;
  color: $brand-primary-160 !important;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease-in-out;
  border-radius: 50%;

  &:hover {
    color: $white !important;
    background-color: $brand-color-dark !important;
  }
}

:global(.react-calendar__tile--now) {
  background-color: $white;
  border: $grey-border !important;
}

:global(.react-calendar__tile--range) {
  border-radius: 0;
  color: $white !important;
  background-color: $brand-color-dark !important;
}

:global(.react-calendar__tile--rangeStart) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

:global(.react-calendar__tile--rangeEnd) {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

// :global(.react-calendar__tile--active) {
//   background: #5e3ee3;
//   color: white;
// }

// :global(.react-calendar__tile--range) {
//   background: #ddd;
//   color: #3c3c3c;
// }

// :global(.react-calendar__tile--rangeStart),
// :global(.react-calendar__tile--rangeEnd) {
//   background: #5e3ee3;
//   color: white;
// }
