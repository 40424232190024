@use 'styles/mixins' as *;

.root {
  min-height: 100vh;
  background-color: $base-grey-3;
  padding-top: 56px;
  border: 1px solid $base-grey-3;
}

.header {
  background-color: $white;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $base-grey-10;
}

.title {
  @include text-h2;
}
