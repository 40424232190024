@use 'styles/mixins' as *;

.order {
  @include flex-box-centered;

  width: 25px;
  min-width: 25px;
  height: 25px;
  color: $base-grey-50;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  grid-column: 1 / span 1;
  justify-self: start;
}

.padding {
  padding-top: 3px;
}
