.root {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  align-items: center;
  color: #747474;
  transition: all 0.4s;
  font-family: inter, sans-serif;
  width: 100%;

  &:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .logo {
    position: absolute;
    top: 7px;
    left: 12px;
    height: 26px;
    width: auto;
  }
}
