@use 'styles/colors' as *;

.root {
  display: flex;
  justify-content: center;
}

// .content {
//   width: 400px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-items: center;

//   & > div {
//     width: 100%;
//   }

//   .title {
//     color: $brand-primary-200;
//     font-size: 34px;
//     font-weight: 900;
//   }

//   .header {
//     color: $brand-primary-160;
//     font-size: 12px;
//     font-weight: bold;
//     text-transform: uppercase;
//   }
// }

// .plan {
//   margin: 2em 0;
//   display: flex;
//   align-items: center;

//   .button {
//     height: 40px;
//     width: 180px;
//   }

//   & > a {
//     padding-left: 1em;
//     color: $brand-primary;
//     font-size: 14px;
//     cursor: pointer;
//     text-decoration: underline;
//   }
// }

// .invoice {
//   padding-top: 2em;
//   margin-bottom: 2em;

//   .title{
//     color: $brand-primary-160;
//     font-size: 12px;
//     font-weight: bold;
//     margin-bottom: 2em;
//   }
// }
