@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;
  background: $background-gradient;
  width: 100vw;
  height: 100vh;

  .card {
    overflow: scroll;
    max-height: 90vh;
  }

  .content {
    width: 500px;
    padding: 60px;
  }

  .logo {
    @include flex-box-centered;
    flex-direction: column;
    max-width: 25rem;
    max-height: 25rem;
    cursor: pointer;
  }

  .scopes {
    margin: 1em 0;
    width: 100%;

    li {
      padding: 1em 0;

      &:not(:last-of-type) {
        border-bottom: $grey-border;
      }
    }
  }
}
