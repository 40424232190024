@import 'styles/mixins';

.root {
  position: relative;

  .input {
    @include input;
    padding-left: 5rem;
    text-transform: uppercase;
  }

  .preview {
    position: absolute;
    margin-top: 1rem;
    width: 3rem;
    height: 2rem;
    border-radius: 4px;
    left: 1.5rem;
    border: 1px solid $base-grey-10;
    z-index: 1;
  }
}

.picker {
  display: none;
  position: absolute;
  left: 1.5em;
  top: 95%;
  right: 1rem;
  width: 20rem;
  height: 18rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
  background: $white;
  border-radius: 4px;

  &Visible {
    display: flex;
    z-index: 2;
  }

  & > div {
    position: relative;
    width: 18rem;
  }

  .hue {
    margin-top: 1rem;
    height: 1.5rem;
    overflow: hidden;
    border-radius: 2px;
  }

  .saturation {
    height: 13rem;
  }

  .hue-horizontal {
    position: relative;
  }

  .saturation-white {
    position: relative;
  }
}
