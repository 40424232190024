.linkedinVerification {
  position: relative;
  width: 100%;
  min-width: 80vw;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}
.linkedinVerification > img {
  max-width: 400px;
}
.linkedinVerification > .title {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  padding-top: 1em;
}
.linkedinVerification > .subtitle {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  color: #2e263e;
}
.linkedinVerification > .note {
  margin-top: 20px;
  font-size: 11px;
  text-align: center;
  color: #514d5a;
}
.linkedinVerification > .linkedinBtn,
.linkedinVerification > a {
  border-radius: 8px;
}
.linkedinVerification > a {
  margin-top: 20px;
  background-color: #eeeeee;
  color: #514d5a;
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s;
  -webkit-transition: background-color 0.4s;
  cursor: pointer;
}
.linkedinVerification > a:hover {
  text-decoration: none;
  background-color: #e4e4e4;
}

@media screen and (max-width: 768px) {
  .linkedinVerification > img {
    max-width: 80%;
    max-height: 20vh;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 426px) {
  .linkedinVerification {
    height: 100vh;
    padding: 20px;
    padding-bottom: 40%;
  }
  .linkedinVerification > .title {
    padding-top: 0.5em;
  }
  .linkedinVerification > .subtitle {
    margin-top: 0;
    font-size: 14px;
  }
  .linkedinVerification > .note {
    margin-top: 10px;
  }
  .linkedinVerification > .linkedinBtn,
  .linkedinVerification > a {
    min-height: 40px;
  }
}
