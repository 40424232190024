@use 'styles/mixins' as *;

.body {
  display: flex;
  justify-content: center;
  align-items: center;

  .dataPicker {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .dropzone {
      display: inline-block;
      cursor: pointer;
      color: $brand-primary;
      text-transform: uppercase;
      font-size: 12px;
      background-color: $white;
      border: $grey-border;
      border-radius: 8px;
      padding: 15px;
    }
  }
}
