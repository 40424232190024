.tabs.nav-tabs {
  padding-left: 30px;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;

  &.padding-small {
    padding-left: 15px;
  }

  .nav-link {
    font-family: urw-gothic, sans-serif;
    color: $brand-primary-160;
    font-size: 14px;
    font-weight: 600;
    transition: color 0.4s;
    -webkit-transition: color 0.4s;
    border: 1px solid transparent;
    background-color: $white;
    border-bottom: $grey-border;
    margin-bottom: -1px;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &.invalid {
      color: $status-error-secondary;
      &.active {
        border-bottom: 2px solid $status-error-secondary;
      }
    }

    &:not(.invalid):not(.disabled):focus {
      background-color: transparent;
      outline: none;
    }

    &:not(.invalid):not(.disabled):hover {
      color: $brand-primary;
      background-color: transparent;
    }

    &:not(.invalid):not(.disabled).active {
      color: $brand-primary;
      border-bottom: 2px solid $brand-primary;
    }

    &.disabled {
      color: $brand-primary-40;
    }
  }
}

.tab-content {
  padding: 30px;

  .tab-pane {
    &.fade {
      &.show {
        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.tab-pane:not(.questionnaires-tabs) .tab-content .tab-pane.fade.show.active {
  overflow-y: auto;
}

.tabs.no-padding + .tab-content {
  padding: 0 !important;
}
